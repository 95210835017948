<template>
  <div>
    <b-table
      :data="postsShown"
      :paginated="paginated && expanded"
      :pagination-simple="false"
      :per-page="perPage"
      v-if="posts.length"
      class="postTable"
    >
      <b-table-column field="type" label="Type" width="80" centered>
        <template v-slot="props">
          <b-icon pack="fal" :icon="props.row.icon" size="is-large" class="bigicon"> </b-icon>
        </template>
      </b-table-column>

      <b-table-column field="theme" label="Theme" :sortable="sortable">
        <template v-slot="props">
          {{ props.row.source }}
          <span class="tag is-success is-light"> {{ props.row.theme }} Frida's dream </span>

          {{ props.row.theme }}
        </template>
      </b-table-column>

      <b-table-column field="text" label="Post " :sortable="sortable" :searchable="searchable">
        <template v-slot="props">
          <figure class="image is-48x48" v-if="props.row.featuredImage" style="float: left">
            <img
              :src="props.row.featuredImage?.url50 || 'https://placekitten.com/48/48'"
              alt="Post Image"
              style="width: 48px; height: 48px; object-fit: cover"
            />
          </figure>
          <div class="elip2">
            {{ props.row.text }}
          </div>
        </template>
      </b-table-column>

      <!-- 
    <b-table-column field="scheduledDate" label="Scheduled Date" :sortable="sortable" centered>
      <template v-slot="props">
        <span class="tag is-success">
          {{ props.row.scheduledDate }}
        </span>
      </template>
    </b-table-column>
    

    <b-table-column field="img" label="Image" :sortable="sortable">
      <template v-slot="props">
        <figure class="image is-48x48" v-if="props.row.featuredImage" style="float: left">
          <img
            :src="props.row.featuredImage?.url50 || 'https://placekitten.com/48/48'"
            alt="Post Image"
            style="width: 48px; height: 48px; object-fit: cover"
          />
        </figure>
      </template>
    </b-table-column> -->

      <b-table-column v-if="!isPosted && !isUpcoming" field="status" label="Status" :sortable="sortable" centered>
        <template v-slot="props">
          {{ props.row.status }}
        </template>
      </b-table-column>

      <b-table-column v-if="isPosted" field="date" label="Posted" :sortable="sortable" centered v-slot="props">
        <span class="tag is-success">
          {{ new Date(props.row.created).toLocaleDateString() }}
        </span>
      </b-table-column>

      <b-table-column v-if="isPosted" field="text" label="Likes" :sortable="sortable" v-slot="props">
        {{ props.row?.likes?.length || 0 }}
      </b-table-column>
      <b-table-column label="Actions">
        <template v-slot="props">
          <!--  
        <router-link v-if="isPosted" :to="{ name: 'ViewPost', params: { postId: props.row.id } }">
          <b-button size="is-small" rounded type="is-primary" icon-left="eye">View</b-button>
        </router-link>
        <router-link :to="{ name: 'EditPost', params: { postId: props.row.id } }">
          <b-button size="is-small" rounded type="is-warning" icon-left="pencil-alt">Edit</b-button>
        </router-link>
      
      
      
        <b-button size="is-small" rounded type="is-danger" icon-left="trash-alt">Delete</b-button>
      
            <router-link :to="props.row.url + '/edit '">
        <b-button type="is-small" rounded> <i class="fal fa-pencil" style=""></i> <span> </span>Edit </b-button>
      </router-link>
    
    -->

          <b-button v-if="isUpcoming" size="is-small" rounded type="is-dark" outlined icon-left="paper-plane"
            >Post Now</b-button
          >

          <!-- view router link-->
          <router-link :to="props.row.url" v-if="isPosted">
            <b-button type="is-small" rounded> <i class="fal fa-eye" style=""></i> <span> </span>View </b-button>
          </router-link>
        </template>
      </b-table-column>
      <!-- 
    <b-table-column field="url600" label="Image" :sortable="sortable" v-slot="props">
      <img :src="props.row?.featuredImage?.url50" style="width: 60px; height: 60px; object-fit: cover" />
    </b-table-column>

    <b-table-column field="source" label="source" width="40" :sortable="sortable" numeric v-slot="props">
      {{ props.row.source }}
    </b-table-column>
 -->
    </b-table>

    <b-button v-if="!expanded" class="is-light btMore" @click="expanded = true"> View more </b-button>
  </div>
</template>

<script>
export default {
  props: {
    posts: {
      type: Array,
      default: () => [],
    },
    paginated: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    maxItems: {
      type: Number,
      default: 9999,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: String,
      default: "upcoming",
    },
    perPage: {
      type: Number,
      default: 10,
    },
    collapsedCount: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    isUpcoming() {
      return this.columns === "upcoming";
    },
    isPosted() {
      return this.columns === "posted";
    },
    postsShown() {
      //depeding on expande, only show the first 3 or perPage count
      if (!this.expanded) {
        return this.posts.slice(0, this.collapsedCount);
      } else {
        return this.posts;
      }

      return this.posts.map((p) => {
        /*
        let status = "Scheduled";
        if (new Date(p.scheduledDate) < new Date()) {
          status = "Posted";
        }
        p.status = status;

        p.icon = p.img ? "image" : "font";
*/
        return p;
      });
    },
  },
};
</script>
<style scoped>
.btMore {
  margin-top: 10px;
}
.bigicon {
  color: #ccc;
}
.elip2 {
  /* 2 lines max elipsis */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.elip3 {
  /* 2 lines max elipsis */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
/* DEEP SELECTOR for nested childs in buefy:
https://vue-loader.vuejs.org/guide/scoped-css.html#child-component-root-elements
*/
.postTable >>> table th {
  color: #ccc;
  font-weight: 400;
}
</style>
