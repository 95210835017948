<template>
  <div>
    <titleBar
      :title="'Integration'"
      :img="bot?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
      :isChild="true"
    />

    <div class="sectionNA">
      <div class="containerNA NHAw800">
        <NewPostTeaser @open="openNewPostModal" :bot="bot" :avatar="bot?.avatar" :avatarLink="bot?.url" />

        <!-- 
        <b-modal
          v-model="isComponentModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-label="Example Modal"
          close-button-aria-label="Close"
          aria-modal
        >
          <template #default="props">
            <new-post-modal
              :bot="bot"
              :type="selectedNewPostItem"
              v-bind="formProps"
              @close="props.close"
            ></new-post-modal>
          </template>
        </b-modal> -->

        <br />
        <b-dropdown v-model="selectedNewPostItem" aria-role="list" v-show="!selectedNewPostItem">
          <template #trigger="{ active }">
            <b-button
              rounded
              label="New post..."
              :class="active ? 'arrow-up' : 'arrow-down'"
              class="is-fullwidth"
              icon-pack="fas"
              type="is-dark"
              icon-left="plus-circle"
            />

            <!-- 
            dfsd {{ active }} - {{ selectedNewPostItem }}
       
            <b-button
              :key="'button-' + index"
              :label="i.title + 666333331"
              type="is-primary"
              :icon-left="i.icon"
              icon-right="menu-down"
            /> -->
          </template>
          <!--
          <b-dropdown-item :value="'Featured'" aria-role="listitem">
            <div class="media">
              <b-icon class="media-left" icon="star"></b-icon>
              <div class="media-content">
                <h3>Featured</h3>
                <small>Featured items</small>
              </div>
            </div>
          </b-dropdown-item>  -->

          <b-dropdown-item v-for="(i, index) in newPostItems" :value="i.title" :key="index" aria-role="listitem">
            <div class="media">
              <b-icon class="media-left" :icon="i.icon" icon-pack="fal"></b-icon>
              <div class="media-content">
                <h3>{{ i.title }}</h3>
                <small>{{ i.description }}</small>
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>

        <!-- INLINE MODAL  -->
        <new-post-modal
          :type="selectedNewPostItem"
          v-if="selectedNewPostItem"
          v-bind="formProps"
          @close="selectedNewPostItem = null"
        ></new-post-modal>

        <!--  
        <br />
        <br />
        <br />

        <b-button
          label="open modal (demo ui, alternative option to the inline box loading on page. better on mobile, but desktop?)"
          type="is-primary"
          size="is-small is-outlined"
          @click="isComponentModalActive = true"
        />
-->
        <hr />
        <h2 class="title is-4">
          <b-field>
            Upcoming Posts

            <b-tag rounded>44 scheduled</b-tag>
          </b-field>
        </h2>

        <div class="buttons filter-button-row">
          <b-button
            v-for="(post, index) in postTypesButtons"
            @click="upcomingPostsFilter = post.id"
            :key="index + 'fsduuw'"
            :class="upcomingPostsFilter == post.id ? 'is-small is-outlined is-dark' : 'is-small'"
            v-show="!post.soon"
          >
            <span class=" ">
              <!--  
                 v-bind:style="{ backgroundColor: post.color }"
                  <i :class="post.icon"></i>  
                     <p v-if="post.soon" class="soon">Coming soon...</p>
                    -->
              {{ post.label }}
            </span>
          </b-button>
          Search...
        </div>

        Action: Shuffle posting schedule --- generate more?

        <div class="box-table box">
          <PostTable :posts="upcomingPosts" columns="upcoming" :maxItems="5" :paginated="upcomingPostsPaginated" />
        </div>
        <br />

        <article class="message is-primary">
          <div class="message-body">
            <h3 class="title is-5">Schedule more AI content</h3>
            <p>
              Based on your <strong>posting schedule</strong>, this bot will run out of content to post in
              <strong> 3 days</strong>.
            </p>
            <br />
            <b-button size="is-small" rounded type="is-dark" outlined icon-left="sparkles"
              >Bulk generate AI content</b-button
            >
          </div>
        </article>

        <br />

        <div v-if="posts && posts.length">
          <br />
          <br />
          <h2 class="title is-4">Recently posted</h2>
          <!-- 
Latest posts
  :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :default-sort-direction="defaultSortDirection"
            :pagination-rounded="isPaginationRounded"
            :sort-icon="sortIcon"
            :sort-icon-size="sortIconSize"
            default-sort="user.first_name"

  :page-input="hasInput"
            :pagination-order="paginationOrder"
            :page-input-position="inputPosition"
            :debounce-page-input="inputDebounce"
-->
          <div class="box">
            <PostTable :posts="postedPosts" columns="posted" :maxItems="5" :paginated="postedPostsPaginated" />
          </div>
        </div>

        <h2 class="title is-4">512 post ideas</h2>

        <div class="buttons filter-button-row">
          <b-button class="is-outlined is-dark"> Random </b-button>
          <b-button v-for="(post, index) in postTypes" :key="index + 'fsduuw'" class=" ">
            <span class=" ">
              <!--  
                 v-bind:style="{ backgroundColor: post.color }"
                  <i :class="post.icon"></i>  
                     <p v-if="post.soon" class="soon">Coming soon...</p>
                    -->
              {{ post.description }}
            </span>
          </b-button>
        </div>
        <br />
        <br />
        <br />
        <br />

        <hr />
        <br />

        <div class="idea-blocks">
          <div class="idea-block" v-for="(i, index) in postTypes" :key="index + 'f8e45'">
            <h2 class="title is-4">
              <b-field>
                {{ i.description }} ideas

                <b-tag rounded>44</b-tag>
              </b-field>
            </h2>
          </div>
        </div>
        <br />
        <br />

        <br />
        <br />
        <br />
        <br />
        <headerManage
          style="display: none"
          title="Post automatically "
          sub="Text, articles and image posts"
          desc="Your bot will post on its own."
          @save="save"
          :posting="posting"
        >
          <!-- Here is where you define the content of the slot -->
          <div>
            <p></p>
            <!-- You can even use other components here -->
          </div>
        </headerManage>

        <h2 class="title is-4">Create new...</h2>

        <div class="post-creator">
          <div class="columns is-mobile is-multiline">
            <div v-for="(post, index) in postTypes" :key="index" class="column is-4-desktop is-6-mobile">
              <router-link :to="post.url" tag="div" class="box" v-bind:style="{ backgroundColor: post.color }">
                <div class="content">
                  <i :class="post.icon"></i>
                  <h2>{{ post.description }}</h2>
                  <p v-if="post.soon" class="soon">Coming soon...</p>
                </div>
              </router-link>
            </div>
          </div>
        </div>

        <router-link :to="bot.url + '/new'">
          <b-button type="is-primary" rounded>
            <i class="fal fa-plus" style=""></i> <span> </span>New Post - legacy screens
          </b-button>
        </router-link>
        <br />

        <router-link :to="bot.url + '/studio'">
          <b-button type="is-dark" rounded>
            <i class="fal fa-plus" style=""></i> <span> </span>Studio (temporary link)
          </b-button>
        </router-link>
        <br />
        <br />

        <b-dropdown size="is-large" aria-role="list">
          <template #trigger="{ active }">
            <b-button
              rounded
              label="New post..."
              :class="active ? 'arrow-up' : 'arrow-down'"
              class="is-fullwidth"
              icon-pack="fas"
              type="is-dark"
              icon-left="plus-circle"
            />
          </template>

          <b-dropdown-item has-link>
            <router-link to="/new-bot">Text update </router-link>
            <router-link to="/new-bot">Upload an Image</router-link>
            <router-link to="/new-bot">GEnerate image </router-link>
          </b-dropdown-item>
          <b-dropdown-item has-link> <router-link to="/new-bot">Articlet</router-link></b-dropdown-item>
        </b-dropdown>

        <hr />
      </div>
    </div>
  </div>
</template>

<script>
//import Editor from "@/components/e/EditorV";
import headerManage from "@/components/studio/headerManage";
import PostTable from "@/components/studio/PostTable.vue";
import NewPostModal from "@/components/studio/NewPostModal.vue";

import NewPostTeaser from "@/components/studio/NewPostTeaser.vue";

const POST_TYPES = [
  {
    icon: "far fa-image",
    color: "#f9dcd1",
    path: "/images",
    description: "Image Post",
    soon: false,
  },
  {
    icon: "far fa-file-alt",
    color: "#d1f9dc",
    path: "/text",
    description: "Text Post",
    soon: false,
  },
  {
    icon: "far fa-newspaper",
    color: "#d1f9f6",
    path: "/article",
    description: "Article Post",
    soon: false,
  },
  {
    icon: "far fa-file-audio",
    color: "#d1d4f9",
    path: "/audio",
    description: "Audio Post",
    soon: true,
  },
  {
    icon: "far fa-file-video",
    color: "#f9d1f6",
    path: "/video",
    description: "Video Post",
    soon: true,
  },
];

const NEW_POST_ITEMS = [
  { title: "Text", description: "Share a text update", icon: "comment-lines" },
  { title: "Article", description: "Write an article", icon: "newspaper" },
  { title: "Image", description: "Upload an image", icon: "upload" },
  { title: "AI image", description: "Generate an image", icon: "sparkles" },
];
export default {
  //name: "readmeEditor",
  components: {
    //Editor,
    headerManage,
    NewPostModal,
    PostTable,
    NewPostTeaser,
  },
  props: {
    bot: {
      type: Object,
      default: null,
    },
    posting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      posts: [],

      //
      isComponentModalActive: false,
      formProps: {
        email: "evan@you.com",
        password: "testing",
      },
      //
      selectedNewPostItem: null,
      upcomingPostsPaginated: true,
      postedPostsPaginated: true,
      upcomingPostsFilter: "all",
      postTypesButtons: [
        {
          icon: "far fa-image",
          color: "#f9dcd1",
          id: "all",
          label: "All",
          soon: false,
        },
        {
          icon: "far fa-image",
          color: "#f9dcd1",
          id: "image",
          label: "Image posts",
          soon: false,
        },
        {
          icon: "far fa-file-alt",
          color: "#d1f9dc",
          id: "text",
          label: "Text Post",
          soon: false,
        },
        {
          icon: "far fa-newspaper",
          color: "#d1f9f6",
          id: "article",
          label: "Article Post",
          soon: false,
        },
        {
          icon: "far fa-file-audio",
          color: "#d1d4f9",
          id: "audio",
          label: "Audio Post",
          soon: true,
        },
        {
          icon: "far fa-file-video",
          color: "#f9d1f6",
          id: "video",
          label: "Video Post",
          soon: true,
        },
      ],

      // readmeJson: defaultReadme, // "432432432",
    };
  },
  methods: {
    save() {
      this.$emit("save");
    },
    openNewPostModal(id) {
      this.selectedNewPostItem = id;
      console.log("openNewPostModal", id);
      this.isComponentModalActive = true;
    },
  },
  computed: {
    newPostItems() {
      return NEW_POST_ITEMS.map((p) => {
        p.url = this.bot.url + "new";
        return p;
      });
    },
    postTypes() {
      return POST_TYPES.map((p) => {
        p.url = this.bot.url + p.path;
        //TODO: MAP ITEMS of the bot, generated ones...
        return p;
      });
    },
    postsEnriched() {
      const icons = {
        image: "image",
        text: "comment-lines",
        article: "newspaper",
        audio: "file-audio",
        video: "file-video",
      };
      return this.posts.map((p) => {
        let status = "Scheduled";
        if (new Date(p.scheduledDate) < new Date()) {
          status = "Posted";
        }
        p.status = status;

        p.type = p.featuredImage ? "image" : "text";
        // p.icon = p.img ? "image" : "font";
        //do a case for
        p.icon = icons[p.type];

        return p;
      });
    },
    upcomingPosts() {
      var tf = this.upcomingPostsFilter;
      return this.postsEnriched.filter((p) => {
        if (tf != "all" && tf != p.type) return 0; // exclude not matching types
        return 1;
        return new Date(p.scheduledDate) > new Date();
      });
    },
    postedPosts() {
      return this.postsEnriched.filter((p) => {
        return 1;
        return new Date(p.scheduledDate) < new Date();
      });
    },
  },
  mounted() {
    // this.readmeJson = this.bot.readmeJson;
    var id = this.$route.params.bot;
    window.API.getBotPosts(id, {}).then((txtttt) => {
      //s(id, { type: "text" }).then((txtttt) => {
      this.posts = txtttt;
      this.loading = false;
      // this.checkIfAllCallsAreCompleted();
      // console.log("PROJJ!", proj, proj.id);
    });
  },
};
</script>

<style>
.box-table {
  padding: 5px;
  padding-top: 0;
}
</style>
